@import "assets/styles/common";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 28px;

  width: 100%;
}

.legend {
  align-self: flex-end;
  display: flex;
  gap: 24px;

  &__item {
    cursor: pointer;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 6px;
    p {
      @include captionRegular;
      color: $colorNeutral80;

      text-transform: capitalize;
    }
    span {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid #892dff;
    }
    &:last-of-type {
      span {
        border: 1px solid #70b6f6;
      }
    }
  }
}

.chart {
  &-container {
    height: 100%;
    height: 400px;

    :global {
      .recharts-cartesian-axis-tick {
        * {
          fill: $colorInactive;
          font-family: Inter;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: right;
        }
      }
    }
  }
}

.chart-tooltip {
  padding: 5px;
  background-color: $colorNeutral90;
  color: white;
  border-radius: 4px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

  .label {
    font-size: 14px;
  }

  .content {
    font-size: 16px;
    text-transform: capitalize;
  }
}
